import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/builds/everyone/dcdcweb-saturday/src/components/Layout/index.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="h1" components={components}>{`We want your consent. Not your data.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We believe your data is your property and that it should be protected as such in law.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The long form version of our data and privacy policy is below - but if you just want to know in a nutshell what data we want, what we use it for, and where it goes, here are those answers:`}</MDXTag>
      <MDXTag name="h3" components={components}>{`When it is data we ask for:`}</MDXTag>
      <MDXTag name="p" components={components}>{`If we want or need data about you that we do not have a license for, we will ask you for signed and explicit permission.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`When it is data you give us that we did not ask for:`}</MDXTag>
      <MDXTag name="p" components={components}>{`If, for example, you email us, then we will have collected data about you in the course of our business. In such cases we will endevour to contact you to inform you how you can see and manage that such data. In the case of an email, we'll email you back with information about the data we have and how you can access and manage it.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`When we share data with a third party or partner:`}</MDXTag>
      <MDXTag name="p" components={components}>{`We will not share data with anyone until we have your `}<MDXTag name="strong" components={components} parentName="p">{`signed and explicit consent`}</MDXTag>{` to do so.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`When a third party shares your data with us:`}</MDXTag>
      <MDXTag name="p" components={components}>{`We do not knowingly receive, process or store personal data from any third party, partner or external organisation unless such data includes `}<MDXTag name="strong" components={components} parentName="p">{`signed and explicit consent`}</MDXTag>{` indicating in clear language that it `}</MDXTag>
      <MDXTag name="h2" components={components}>{`We don't install tracking cookies.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We don't do tracking on domains that we control. If you're using a modern browser, you can verify what cookies we're using for dcdc.io. In Chrome/ium and Firefox based browsers you can click the padlock in the address bar to verify this claim.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`We don't use tickboxes as consent.`}</MDXTag>
      <MDXTag name="p" components={components}>{`If we need or want your data, we will require `}<MDXTag name="strong" components={components} parentName="p">{`signed and explicit`}</MDXTag>{` consent.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`We don't share things with our partners unless you say so.`}</MDXTag>
      <MDXTag name="p" components={components}>{`If we share your data with a partner, we first require `}<MDXTag name="strong" components={components} parentName="p">{`signed and explicit`}</MDXTag>{` consent.`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h1" components={components}>{`Privacy Policy`}</MDXTag>
      <MDXTag name="p" components={components}>{`DCDC (The Digital Cooperative Development Consortium Ltd) is a cooperative and a member of CoTech and Cooperatives UK. This privacy policy will explain how our organization uses the personal data we collect from you when you use our website.`}</MDXTag>
      <MDXTag name="h4" components={components}>{`Topics:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`What data do we collect?`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`How do we collect your data?`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`How will we use your data?`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`How do we store your data?`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Marketing`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`What are your data protection rights?`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`What are cookies?`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`How do we use cookies?`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`What types of cookies do we use?`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`How to manage your cookies`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Privacy policies of other websites`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Changes to our privacy policy`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`How to contact us`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`How to contact the appropriate authorities`}</MDXTag>
      </MDXTag>
      <MDXTag name="h3" components={components}>{`What data do we collect?`}</MDXTag>
      <MDXTag name="p" components={components}>{`DCDC collects the following data:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Personal identification information (Name, email address, phone number, etc.) when you explicitly and actively consented to such collections, or when you have engaged in business or communications such that a record of contact information is required.`}</MDXTag>
      </MDXTag>
      <MDXTag name="h3" components={components}>{`How do we collect your data?`}</MDXTag>
      <MDXTag name="p" components={components}>{`You directly provide DCDC with all of the data we collect. We collect data and process data when you:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Register online or place an order for any of our products or services.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Voluntarily provide feedback, complete surveys, communicate in forums and message boards, or directly send us email.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`DCDC may also receive your data indirectly from the following sources:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`We don't collect or extract data indirectly.`}</MDXTag>
      </MDXTag>
      <MDXTag name="h3" components={components}>{`How will we use your data?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Our Company collects your data so that we can:`}</MDXTag>
      <MDXTag name="p" components={components}>{`Process orders and manage customer and client accounts.
Manage our cooperative membership register.
Contact you with direct marketing in such instances where you have provided verified active consent.`}</MDXTag>
      <MDXTag name="p" components={components}>{`If you actively consent, DCDC will share your data with partner organisations.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Because we require active consent to share your data, we do not have a list of partner organisations`}</MDXTag>
      <MDXTag name="p" components={components}>{`When Our Company processes your order, it may send your data to, and also use the resulting information from, credit reference agencies to prevent fraudulent purchases.`}</MDXTag>
      <MDXTag name="p" components={components}>{`How do we store your data?
Our Company securely stores your data at `}{`[enter the location and describe security precautions taken]`}{`.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Our Company will keep your `}{`[enter type of data]`}{` for `}{`[enter time period]`}{`. Once this time period has expired, we will delete your data by `}{`[enter how you delete users’ data]`}{`.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Marketing
Our Company would like to send you information about products and services of ours that we think you might like, as well as those of our partner companies.`}</MDXTag>
      <MDXTag name="p" components={components}>{`[List organizations that will receive data]`}{`
If you have agreed to receive marketing, you may always opt out at a later date.`}</MDXTag>
      <MDXTag name="p" components={components}>{`You have the right at any time to stop Our Company from contacting you for marketing purposes or giving your data to other members of the Our Company Group.`}</MDXTag>
      <MDXTag name="p" components={components}>{`If you no longer wish to be contacted for marketing purposes, please click here.`}</MDXTag>
      <MDXTag name="p" components={components}>{`What are your data protection rights?
Our Company would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:`}</MDXTag>
      <MDXTag name="p" components={components}>{`The right to access – You have the right to request Our Company for copies of your personal data. We may charge you a small fee for this service.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The right to rectification – You have the right to request that Our Company correct any information you believe is inaccurate. You also have the right to request Our Company to complete the information you believe is incomplete.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The right to erasure – You have the right to request that Our Company erase your personal data, under certain conditions.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The right to restrict processing – You have the right to request that Our Company restrict the processing of your personal data, under certain conditions.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The right to object to processing – You have the right to object to Our Company’s processing of your personal data, under certain conditions.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The right to data portability – You have the right to request that Our Company transfer the data that we have collected to another organization, or directly to you, under certain conditions.`}</MDXTag>
      <MDXTag name="p" components={components}>{`If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email:`}</MDXTag>
      <MDXTag name="p" components={components}>{`Call us at:`}</MDXTag>
      <MDXTag name="p" components={components}>{`Or write to us:`}</MDXTag>
      <MDXTag name="p" components={components}>{`Cookies
Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology`}</MDXTag>
      <MDXTag name="p" components={components}>{`For further information, visit allaboutcookies.org.`}</MDXTag>
      <MDXTag name="p" components={components}>{`How do we use cookies?
Our Company uses cookies in a range of ways to improve your experience on our website, including:`}</MDXTag>
      <MDXTag name="p" components={components}>{`Keeping you signed in
Understanding how you use our website
`}{`[Add any uses your company has for cookies]`}{`
What types of cookies do we use?
There are a number of different types of cookies, however, our website uses:`}</MDXTag>
      <MDXTag name="p" components={components}>{`Functionality – Our Company uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.
Advertising – Our Company uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address. Our Company sometimes shares some limited aspects of this data with third parties for advertising purposes. We may also share online data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown advertising based on your browsing patterns on our website.
`}{`[Add any other types of cookies your company uses]`}{`
How to manage cookies
You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Privacy policies of other websites
The Our Company website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Changes to our privacy policy
Our Company keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 9 January 2019.`}</MDXTag>
      <MDXTag name="p" components={components}>{`How to contact us
If you have any questions about Our Company’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Email us at:`}</MDXTag>
      <MDXTag name="p" components={components}>{`Call us:`}</MDXTag>
      <MDXTag name="p" components={components}>{`Or write to us at:`}</MDXTag>
      <MDXTag name="p" components={components}>{`How to contact the appropriate authority
Should you wish to report a complaint or if you feel that Our Company has not addressed your concern in a satisfactory manner, you may contact the Information Commissioner’s Office.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Email:`}</MDXTag>
      <MDXTag name="p" components={components}>{`Address`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    